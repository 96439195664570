<template>
  <div
    style="width: 100%; height: 100%; text-align: center; padding-top: 100px"
    v-if="state.loaderEnabled"
  >
    <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
  </div>
  <transition name="fade">
    <div class="card" v-if="!state.loaderEnabled">
      <div class="card-header border-0 pt-6">
        <SearchAndFilter
          :listInitial="state.initialMyList"
          :filters="state.tableFilters"
          :searchDefault="state.searchDefault"
          @returnSearch="state.myList = $event"
          @returnLoadingDatatable="state.loadingDatatable = $event"
          ExcelFilename="Stillnetwork_Offres_commerciales"
          :ExcelFormat="ExcelFormat"
        ></SearchAndFilter>
      </div>
      <div class="card-body pt-0">
        <div
          v-if="state.myList.length == 0"
          class="
            alert
            bg-light-primary
            alert-primary
            d-flex
            align-items-center
            p-5
            mb-10
          "
        >
          <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
            <inline-svg src="media/icons/duotune/general/gen048.svg" />
          </span>
          <div class="d-flex flex-column">
            <h4 class="mb-1 text-dark">{{$t("Aucune offre")}}</h4>
            <span
              >{{$t("Il n'existe aucune offre à afficher correspondant à votre critère de recherche.")}}</span
            >
          </div>
        </div>
        <Datatable
          v-if="state.myList && state.myList.length > 0"
          :table-data="state.myList"
          :table-header="tableHeader"
          :enable-items-per-page-dropdown="true"
          :empty-table-text="$t('Aucune offre à afficher.')"
          :key="state.loadingDatatable"
        >
          <template v-slot:cell-tof_code="{ row: data }">
            <span class="text-primary">{{ data.tof_code }}</span>
          </template>
          <template v-slot:cell-tof_date_creation="{ row: data }">
            {{ formatDate(data.tof_date_creation) }}
          </template>
          <template v-slot:cell-con_nom="{ row: data }">
            {{ data.con_prenom }} {{ data.con_nom }}
          </template>
          <template v-slot:cell-pro_nom_projet="{ row: data }">
            <div style="margin-left: 15px" class="text-gray-800">
              <strong>{{ data.pro_nom_projet }}</strong>
              <br />
              <div
                style="margin-left: 15px"
                class="text-gray-400"
                v-if="data.toc_num_commande_client"
              >
                <span class="w-150px fs-8">{{$t("N° Commande Client")}}</span>
                <span style="margin-left: 15px" class="text-gray-600 fs-7">{{
                  data.toc_num_commande_client
                }}</span>
              </div>
              <div style="margin-left: 15px" class="text-gray-400">
                <span class="w-150px fs-8">{{$t("N° Offre Stillnetwork")}}</span>
                <span style="margin-left: 23px" class="text-gray-600 fs-7">
                  <b>{{ data.tof_code }}</b>
                </span>
              </div>
            </div>
          </template>
          <template v-slot:cell-tof_status="{ row: data }">
            <span
              class="mt-2 mb-2 btn bg-white btn-sm btn-text-dark"
              :class="styleBtnStatus(data.tof_status)"
              style="cursor: default"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg :src="styleImgStatus(data.tof_status)" />
              </span>
              {{ $t(data.tof_status) }}
            </span>
          </template>
          <template v-slot:cell-tof_total_ht="{ row: data }">
            <div>
              <span class="text-gray-700"
                >{{ formatMoney(data.tof_total_ht) }} {{ data.dev_sigle }}</span
              >
            </div>
          </template>
          <template v-slot:cell-tof_seq="{ row: data }">
            <a
              :test="data"
              class="
                btn btn-sm btn-active-primary
                me-2
                mb-2
                btn-bg-white btn-text-dark
              "
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_2"
              @click="OffrePDF(data)"
            >
              <span class="svg-icon svg-icon-1 svg-icon-primary">
                <i class="far fa-file-pdf fs-2 text-primary"></i> </span
              >{{$t("Offre PDF")}}</a
            >
          </template>
        </Datatable>
      </div>
    </div>
  </transition>
  <el-drawer
    v-model="state.drawer"
    title=""
    :with-header="false"
    size="90%"
    modal-class="modal-offre"
  >
    <div class="card shadow-none" style="width: 100%; height: 100%">
      <!--begin::Header-->
      <div class="card-header" id="kt_activities_header">
        <h3 class="card-title fw-bolder text-dark" v-if="state.mOffre.tof_code">
          {{$t("Offre commerciale N°")}} {{ state.mOffre.tof_code }} {{$t("du")}}
          {{ formatDate(state.mOffre.tof_date_creation) }}
        </h3>
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-icon btn-active-light-primary me-n5"
            @click="state.drawer = false"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
            </span>
          </button>
        </div>
      </div>
      <!--end::Header-->

      <!--begin::Body-->
      <div class="card-body position-relative" id="kt_activities_body">
        <iframe
          :src="state.sourcePDF"
          frameborder="0"
          marginwidth="0"
          marginheight="0"
          scrolling="no"
          width="100%"
          height="100%"
          style="
            border: 1px solid #ccc;
            border-width: 1px;
            margin-bottom: 5px;
            max-width: 100%;
            widhth: 100%;
            height: 100%;
          "
          allowfullscreen
        >
        </iframe>
      </div>
      <!--end::Body-->
      <!--end::Footer-->
    </div>
  </el-drawer>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as moment from "moment";
import { useRouter } from "vue-router";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import { useI18n } from "vue-i18n";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Liste des offres commerciales",
  components: {
    Datatable,
    SearchAndFilter,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      loaderEnabled: true,
      myList: [],
      drawer: ref(false),
      sourcePDF: "",
      mOffre: "",
      initialMyList: [],
      loadingDatatable: 0,
      tableFilters: [] as any,
      searchDefault: "",
    });
    const { t } = useI18n()

    const tableHeader = ref([
      {
        name: "Projet",
        key: "pro_nom_projet",
        sortable: false,
      },
      {
        name: "Date",
        key: "tof_date_creation",
        sortable: true,
      },
      {
        name: "Demandeur",
        key: "con_nom",
        sortable: true,
      },
      {
        name: "Statut",
        key: "tof_status",
        sortable: true,
      },
      {
        name: "Montants HT",
        key: "tof_total_ht",
        sortable: true,
      },
      {
        name: "Afficher",
        key: "tof_seq",
        sortable: true,
      },
    ]);

    const ExcelFormat = ref({
      Projet: "pro_nom_projet",
      "Numero commande client": "toc_num_commande_client",
      "Numéro Offre Stillnetwork": "tof_code",
      Date: "tof_date_creation",
      Demandeur: "con_prenom_nom",
      Statut: "tof_status",
      "Montants HT": "tof_total_ht",
    });

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const formatMoney = (n) => {
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const styleBtnStatus = (status) => {
      if (status == t("Offre validée")) return "btn-icon-success";
      if (status == t("Offre déclinée")) return "btn-icon-danger";
      return "btn-icon-info";
    };

    const styleImgStatus = (status) => {
      if (status == t("Offre validée"))
        return "media/icons/duotune/general/gen048.svg";
      if (status == t("Offre déclinée"))
        return "media/icons/duotune/general/gen050.svg";
      return "media/icons/duotune/general/gen049.svg";
    };

    const OffrePDF = async (mOffre) => {
      state.mOffre = mOffre;

      const getGedByTypeId = await mAxiosApi.prototype.getAxios("/getGedByTypeId/Offre/" + mOffre.tof_seq );
      const records = getGedByTypeId.records;

      const srcPDF = "data:application/pdf;base64," + records[0].ged_document;
      state.sourcePDF = srcPDF;
      state.drawer = true;
    };

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Liste des offres commerciales", [
        "Home",
        "Board Stillnetwork",
      ]);

      let myList = await mAxiosApi.prototype.getAxios("/allOffres");
      state.initialMyList = myList.results;
      state.myList = myList.results;
      state.loaderEnabled = false;

      state.tableFilters = ref([
        {
          name: "Demandeurs", key: "con_seq", label: "con_prenom_nom", },
        {
          name: "Status", key: "tof_status", label: "tof_status",
          default : state.myList.filter(function (data: any) { return data.tof_status == t('Offre envoyée') }).length > 0 ? ['Offre envoyée'] : null,
        },
      ]);

      if (router.currentRoute.value.params.code) {
        const index = state.myList.findIndex(
          (object: any) =>
            object.tof_code === router.currentRoute.value.params.code
        );
        state.searchDefault = router.currentRoute.value.params.code + ""
        OffrePDF(state.myList[index]);
      }else {
        // myList
      }
    });

    return {
      tableHeader,
      state,
      formatDate,
      formatMoney,
      styleBtnStatus,
      styleImgStatus,
      OffrePDF,
      ExcelFormat,
    };
  },
});
</script>
